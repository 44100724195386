// To update the points you only need to change properties here

export const adjectives = {
  jobs: [
    {
      label: 'Piloté',
      position: {
        // The position attributes means that you will change the dot position on the Map
        x: 25, // Both properties x and y are in %
        y: -8,
      },
    },
    {
      label: 'Proactif',
      position: {
        x: 20,
        y: -10,
      },
    },
    {
      label: 'Enthousiaste',
      reverse: true,
      position: {
        x: 35,
        y: 80,
      },
    },
    {
      label: 'Teamplayer',
      reverse: true,
      position: {
        // The position attributes means that you will change the dot position on the Map
        x: 40, // Both properties x and y are in %
        y: 103,
      },
    },
    {
      label: 'Passionné',
      position: {
        x: 40,
        y: -7,
      },
    },
    {
      label: 'Entrepreneur',
      position: {
        x: 20,
        y: 15,
      },
    },
    // {
    //   label: 'Personnalisé',
    //   position: {
    //     x: 25,
    //     y: 45,
    //   },
    // },
  ],
}
