





























import type { GalleryItem } from '@/inc/types'

import { defineComponent, PropType, ref, onMounted } from '@vue/composition-api'

import { adjectives } from '@/inc/data/adjectives'

import FlexibleGallery from '@/components/flexible/Gallery.vue'

interface GalleryAdjectives {
  title: string
  gallery: GalleryItem[]
  htmltext?: string
}

export default defineComponent({
  name: 'GalleryAdjectives',
  components: { FlexibleGallery },
  props: {
    content: {
      type: Object as PropType<GalleryAdjectives>,
      required: true,
    },
  },

  setup() {
    const adjectivesEl = ref<HTMLElement[]>([])

    // Place adjectives on pictures
    const placeAdjectives = () => {
      const data = adjectives.jobs

      const update = (el: HTMLElement, position: { x: number; y: number }) => {
        const { x, y } = position

        el.style.left = `${x}%` // Some calculations based on image size…
        el.style.top = `${y}%`
      }

      data.forEach(
        (
          item: { position: { x: number; y: number }; reverse: boolean },
          index: number
        ) => {
          if (!adjectivesEl.value[index]) {
            return
          }

          update(adjectivesEl.value[index], item.position)

          if (item.reverse) {
            adjectivesEl.value[index].classList.add('reverse')
          }
        }
      )
    }

    onMounted(() => placeAdjectives())

    return {
      adjectivesEl,
    }
  },
})
